import * as React from "react";
import { css } from "@emotion/core";

import { locale } from "../../config";
import Modal from "../modal/Modal";
import { SubscriptionModal } from "../subscriptionNewVersion";
import { SubscriptionBlock } from "../SubscriptionBlock";
import { IStripePlan } from "@fitplan/lib/types";

import "./subscribeButtons.scss";
import { processStripePlan } from "@fitplan/lib/utils";
import { useStrings } from "@fitplan/context/lib-es/strings";
import useMount from "react-use/lib/useMount";
import { IPromoData } from "pages/subscribe";

export interface Props {
    stripeProductId: string;
    stripePlans: IStripePlan[];
    title?: string;
    subtitle?: string;
    partner?: string;
    mobileImageSrc?: string;
    backgroundColor?: string;
    filter?: (plan: IStripePlan) => boolean;
    newCustomersOnly?: React.ReactNode;
    allowCoupon?: boolean;
    couponCode?: string;
    promoData?: IPromoData;
}

const SubscribeButtons: React.SFC<Props> = ({
    couponCode,
    mobileImageSrc,
    stripePlans: basePlans,
    stripeProductId,
    subtitle,
    title,
    backgroundColor,
    newCustomersOnly,
    allowCoupon,
    promoData,
    filter,
    partner,
    children,
}) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    useMount(() => { if (promoData?.showModal) { setModalOpen(true) }});

    let stripePlans = filter ? basePlans.filter(filter) : basePlans;
    const [
        selectedPlan,
        setRawSelectedPlan,
    ] = React.useState<IStripePlan | null>(promoData ? stripePlans[0] : null);
    const setSelectedPlan = (plan: IStripePlan | null) => {
        setRawSelectedPlan(plan ? processStripePlan(plan) : null);
    };
    const result = stripePlans.sort(
        (a: IStripePlan, b: IStripePlan) => b.amount - a.amount
    );
    const subscribePage = useStrings("subscribePage");
    const subscriptionBlock = useStrings("homeSubscriptionBlock");

    return (
        <React.Fragment>
            <Modal open={modalOpen}>
                <div
                    css={css`
                        width: 100vw;
                        min-height: 100vh;
                        background: #111213;
                        z-index: 99999;
                        left: 0;
                        top: 0;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    {modalOpen && (
                        <SubscriptionModal
                            allowCoupon={typeof allowCoupon === "boolean" ? allowCoupon : true}
                            couponCode={couponCode}
                            selectedPlan={selectedPlan}
                            partner={partner}
                            availablePlans={stripePlans}
                            newCustomersOnly={newCustomersOnly}
                            selectedInvoice={null}
                            promoData={promoData}
                        />
                    )}
                </div>
            </Modal>
            <SubscriptionBlock
                title={title || subscribePage.title}
                subtitle={subtitle || subscribePage.subtitle}
                buttonText={subscriptionBlock.primaryButton}
                secondaryButtonText={subscriptionBlock.secondaryButton}
                language={locale as "en" | "es"}
                productId={stripeProductId}
                stripePlans={result}
                mobileImageSrc={mobileImageSrc}
                backgroundColor={backgroundColor}
                promoData={promoData}
                onSubscribe={plan => {
                    setSelectedPlan(plan);
                    setModalOpen(true);
                }}
                filter={filter}
            >{children}</SubscriptionBlock>
        </React.Fragment>
    );
};

export default SubscribeButtons;
