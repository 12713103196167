import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { IStripePlan } from "@fitplan/lib/types";
import { getSymbolFromCurrency } from "../../global/currency";

interface Props {
    plan: IStripePlan & {
        overrideTitle?: string;
        overrideFooter?: string;
    };
    buttonText?: string;
    language: "en" | "es";
    primary?: boolean;
    onClick: () => void;
}

export const NewSubscribeButtonBare: React.FunctionComponent<{
    title: string;
    price: string;
    interval: string;
    footer: string;
    text: string;
    onClick: () => void;
}> = ({ title, price, interval, footer, text, onClick }) => {
    return (
        <>
            <Button onClick={onClick}>
                <Container>
                    <Name>{title}</Name>
                    <Price>
                        <Hidden>
                            {price}
                            <Month>/{interval}</Month>
                        </Hidden>
                        <Positioned>
                            {price}
                            <Month>/{interval}</Month>
                        </Positioned>
                    </Price>
                    <Total>{footer}</Total>
                    <Action>
                        {text}
                        <Arrow
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                        >
                            <path
                                fill="#FFF"
                                fillRule="nonzero"
                                d="M15.61 6.557L9.332.281 7.448 2.167l4 4H0v2.666h11.448l-4 4 1.885 1.886 6.276-6.276c.52-.521.52-1.365 0-1.886z"
                            />
                        </Arrow>
                    </Action>
                </Container>
            </Button>
        </>
    );
};

export const NewSubscribeButton: React.FunctionComponent<Props> = ({
    buttonText,
    language,
    onClick,
    plan: stripePlan,
    primary,
}) => {
    const intervalShort = language === "es" ? "mes" : "mo";

    let title = language === "es" ? "MENSUAL" : "MONTHLY";
    let textPeriod = language === "es" ? "al mes" : "every month";
    let currencySymbol = getSymbolFromCurrency(
        stripePlan.currency.toUpperCase()
    );
    let amount = stripePlan.amount;
    if (stripePlan.interval === "month" && stripePlan.interval_count === 3) {
        title = language === "es" ? "TRIMESTRAL" : "QUARTERLY";
        textPeriod = language === "es" ? "cada tres meses" : "every 3 months";
        amount = Math.floor(amount / 3);
    } else if (stripePlan.interval === "year") {
        title = language === "es" ? "ANUAL" : "YEARLY";
        textPeriod = language === "es" ? "facturados hoy" : "once a year";
        amount = Math.floor(amount / 12);
    }
    const amountText = `${currencySymbol}${stripePlan.amount / 100}${
        language === "es" && currencySymbol === "$" ? " USD" : ""
    }`;
    const footer =
        language === "es"
            ? `Paga ${amountText} ${textPeriod}`
            : `${amountText} billed ${textPeriod}`;
    let btnText = buttonText
        ? buttonText
        : language === "es"
        ? "SUSCRÍBETE AHORA"
        : "START FREE";

    return (
        <>
            <Button primary={primary} onClick={onClick}>
                <Container>
                    <Name>{stripePlan.overrideTitle || title}</Name>
                    <Price>
                        <Hidden>
                            {`${currencySymbol}${amount / 100}`}
                            <Month>/{intervalShort}</Month>
                        </Hidden>
                        <Positioned>
                            {`${currencySymbol}${amount / 100}`}
                            <Month>/{intervalShort}</Month>
                        </Positioned>
                    </Price>
                    <Total>{stripePlan.overrideFooter || footer}</Total>
                    <Action>
                        {btnText}
                        <Arrow
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                        >
                            <path
                                fill="#FFF"
                                fillRule="nonzero"
                                d="M15.61 6.557L9.332.281 7.448 2.167l4 4H0v2.666h11.448l-4 4 1.885 1.886 6.276-6.276c.52-.521.52-1.365 0-1.886z"
                            />
                        </Arrow>
                    </Action>
                </Container>
            </Button>
        </>
    );
};

const Button = styled.button<{ primary?: boolean }>`
    margin: 12px auto;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.15);
    background: ${props =>
        props.primary
            ? `linear-gradient(to left, #1144ff, #0076f9);`
            : `#3a3b3e`};
    border: none;

    :focus,
    :active {
        outline: none;
    }

    :hover {
        box-shadow: 0 8px 16px 10px rgba(80, 80, 80, 0.15);
        cursor: pointer;

        :disabled {
            cursor: none;
        }
    }

    :disabled {
        box-shadow: none;
    }

    width: 100%;
    max-width: 480px;
    display: block;
    color: white;
    box-sizing: border-box;
`;

const baseTextStyle = css`
    font-family: Barlow;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
`;

const Container = styled.div`
    display: grid;

    grid-template-areas:
        "name name name . ."
        "price price . . action"
        "price price total total total";
    grid-template-columns: auto auto auto 1fr auto;
    grid-template-rows: 1fr auto 1fr;
    grid-gap: 4px;
`;

const Name = styled.div`
    grid-area: name;
    text-align: start;

    ${baseTextStyle};
    margin-left: 4px;
`;

const Price = styled.div`
    position: relative;
    grid-area: price;
    ${baseTextStyle};
    font-size: 32px;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 32px;
    color: #0076f9;

    padding: 0 4px;
`;

const Month = styled.span`
    ${baseTextStyle};
    color: #787878;
`;

const Total = styled.div`
    grid-area: total;
    ${baseTextStyle};
    margin-left: 6px;
    margin-top: -4px;
    text-align: start;
    font-size: 15px;

    @media screen and (min-width: 500px) {
        font-size: 17px;
        margin-top: -8px;
    }
`;

const Action = styled.div`
    position: relative;
    grid-area: action;

    ${baseTextStyle}
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    padding-right: 22px;
    margin-top: -4px;
    @media screen and (min-width: 500px) {
        font-size: 17px;
    }
`;

const Hidden = styled.span`
    visibility: hidden;
`;

const Positioned = styled.span`
    position: absolute;
    bottom: 4px;
    left: 2px;
    box-sizing: border-box;

    border-radius: 5px;
    background-color: #ffffff;
    padding: 4px;
`;

const Arrow = styled.svg`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 8px;
`;
