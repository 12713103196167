import * as React from 'react';
import Proptypes from 'prop-types';
import { createLocaleTextGetter } from '../../utils/l10n';

function localize(Component) {
  return class Localize extends React.Component {
    static defaultProps = { data: {} };
    static propTypes = {
      data: Proptypes.shape({}),
    };
    constructor(props) {
      super(props);

      this.getLocalizedContent = createLocaleTextGetter(
        process.env.GATSBY_LOCALE
      );
    }

    render() {
      return (
        <Component
          {...this.props}
          data={this.getLocalizedContent(this.props.data)}
        />
      );
    }
  };
}

export default localize;
