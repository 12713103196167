import styled from "@emotion/styled";

export const desktopBreakpoint = "800px";

export const Container = styled.div<{mobileImageSrc?: string, backgroundColor?: string}>`
    width: 100%;
    font-family: Barlow Condensed, sans-serif;
    background: ${ props => props.mobileImageSrc ? props.backgroundColor || `#111213` : props.backgroundColor || `linear-gradient(to bottom, #1b1c1c, #212121)`};

    box-sizing: border-box;
    padding: 24px 8px;
`;

export const MobileImageContainer = styled.div`
    position: relative;
    width: 100vw;
    margin-bottom: 24px;

    @media screen and (min-width: ${desktopBreakpoint}) {
        display: none;
    }
`;

export const MobileImage = styled.img`
    width: 100vw;
    height: auto;
`;

export const ImageMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100px;

    background-image: linear-gradient(to bottom, rgba(17, 18, 19, 0) 30%, #111213);
`;

export const DesktopContainer = styled.div`
    display: none;
    padding-top: 72px;
    padding-bottom: 36px;

    @media screen and (min-width: ${desktopBreakpoint}) {
        display: block;
    }
`;

export const MobileText = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0 16px;

    font-family: Barlow, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    

    h1 {
        font-size: 32px;
        font-weight: bold;
        font-stretch: condensed;
        margin-bottom: 16px;
    }

    h2 {
        font-size: 17px;
    }
`;