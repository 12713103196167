import * as React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { IStripePlan } from "@fitplan/lib/types";
import { useTatari } from "@fitplan/lib/analytics/useTatari";

import { useAxios } from "../../hooks/useAxios";
import {
    Container,
    DesktopContainer,
    MobileImage,
    MobileImageContainer,
    ImageMask,
    desktopBreakpoint,
    MobileText,
} from "./layout";
import * as config from "../../config";
import { NewSubscribeButton } from "../subscribe/NewSubscribeButton";
import { IPromoData } from "../../pages/subscribe";

export type Props = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    mobileImageSrc?: string;
    className?: string;

    language?: "en" | "es";

    productId?: string;
    stripePlans: IStripePlan[];
    filter?: (plan: IStripePlan) => boolean;

    buttonText?: string;
    secondaryButtonText?: string;
    backgroundColor?: string;

    promoData?: IPromoData;

    onSubscribe: (plan: IStripePlan) => void;
};

export const SubscriptionBlock: React.SFC<Props> = props => {
    const {
        title,
        subtitle,
        language,
        productId,
        buttonText,
        secondaryButtonText,
        mobileImageSrc,
        onSubscribe,
        backgroundColor,
        className,
        stripePlans,
        filter,
        promoData,
        children,
    } = props;
    const override = (plan: IStripePlan) => {
        if (plan.interval === "year" && promoData?.override?.yearly) {
            const override = promoData?.override?.yearly;
            if (override.price) {
                return {
                    ...plan,
                    amount: override.price,
                    realAmount: plan.amount,
                    overrideTitle: promoData?.coupon ? "NEW MEMBERS" : "",
                };
            }
        }
        return plan;
    };
    const [{ loading, error, data }, refetch] = useAxios(
        {
            url: `${config.urls.getStripePlans}?productId=${productId}`,
            method: "GET",
        },
        { defaultData: { result: stripePlans } }
    );
    const plans = React.useMemo(
        () => getPlans(loading, error, data, stripePlans, filter).map(override),
        [loading, error, data, stripePlans, filter]
    );
    const currency = plans[0].currency.toUpperCase();
    const showNote = ["USD", "AUD", "CAD", "MXN"].indexOf(currency) > -1;
    const { tatariTrack } = useTatari();
    return (
        <Container
            className={className}
            backgroundColor={backgroundColor}
            mobileImageSrc={mobileImageSrc}
        >
            {mobileImageSrc ? (
                <>
                    <MobileImageContainer>
                        <MobileImage src={mobileImageSrc} />
                        <ImageMask />
                        <MobileText>
                            <h1>{title}</h1>
                            <h2>{subtitle}</h2>
                        </MobileText>
                    </MobileImageContainer>
                    <DesktopContainer>
                        <TitleMargin>{title}</TitleMargin>
                        <SubtitleMargin>{subtitle}</SubtitleMargin>
                    </DesktopContainer>
                </>
            ) : (
                <>
                    <TitleMargin>{title}</TitleMargin>
                    <SubtitleMargin>{subtitle}</SubtitleMargin>
                </>
            )}
            <MobileMargin>
                {plans.map((plan, index) => (
                    <NewSubscribeButton
                        buttonText={
                            index === 0 ? buttonText : secondaryButtonText
                        }
                        primary={index === 0}
                        language={language}
                        plan={plan}
                        key={plan.id || plan.planId}
                        onClick={() => {
                            tatariTrack("add-to-cart", {
                                plan: plan,
                                planId: plan.id || plan.planId,
                            });
                            onSubscribe(plan);
                        }}
                    />
                ))}
                {children}
            </MobileMargin>
            {showNote && (
                <CurrencyNote>
                    * All prices shown are in {currency}
                </CurrencyNote>
            )}
        </Container>
    );
};

const blackFridayDeal = (
    plan: IStripePlan
): IStripePlan & {
    overrideTitle?: string;
    overrideFooter?: string;
} => {
    // if (plan.interval === "year") {
    //     return {
    //         ...plan,
    //         overrideTitle: "NEW YEAR SALE",
    //         overrideFooter: "$50 ANNUAL MEMBERSHIP",
    //     };
    // }
    return plan;
};

const getPlans: (
    loading: boolean,
    error: any,
    data: any,
    plans: IStripePlan[],
    filter?: (plan: IStripePlan) => boolean
) => IStripePlan[] = (loading, error, data, plans, filter = () => true) => {
    if (!error && !loading && data && data.result) {
        const plans = (data.result as IStripePlan[]).filter(
            plan => (plan || {}).active
        );
        return plans
            .filter(filter)
            .sort((a, b) => b.amount - a.amount)
            .map(blackFridayDeal);
    }
    return plans.filter(filter).map(blackFridayDeal);
};

const baseTextStyle = css`
    font-family: Barlow, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
`;

const SubtitleMargin = styled.div`
    ${baseTextStyle};
    font-size: 21px;

    margin: 32px 16px;
    text-align: center;
    @media (min-width: ${desktopBreakpoint}) {
        margin: 32px 0;
    }
`;

const TitleMargin = styled.div`
    ${baseTextStyle};
    font-weight: bold;
    font-stretch: condensed;
    font-size: 48px;
`;

const MobileMargin = styled.div`
    margin: 0 8px;
    @media screen and (min-width: ${desktopBreakpoint}) {
        margin: 0;
    }
`;

const CurrencyNote = styled.div<{ currencyNoteColor?: string; extraCss?: any }>`
    ${baseTextStyle}
    margin: 8px;
    font-size: 14px;
    text-align: center;
    color: ${props => props.currencyNoteColor || "white"};
    ${props => props.extraCss};
`;

export default SubscriptionBlock;
