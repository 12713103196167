import * as React from "react";
import "./Polygon.scss";

export interface Props {
    polygonColor: string;
    borderColor?: string;
}

export const Polygon: React.FunctionComponent<Props> = (props) => (
    <div className="polygon">
        <svg className="polygon__svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            {props.borderColor ? (
                <svg>
                    <polygon
                        points="0,0 0,100 100,100 100,0 50,96"
                        style={{
                            fill: props.polygonColor,
                        }}
                    />
                    <polygon
                        points="0,0 0,3 50,99 50,96"
                        style={{
                            fill: props.borderColor,
                        }}
                    />
                    <polygon
                        points=" 50,99 50,96 100,0 100,3"
                        style={{
                            fill: props.borderColor,
                        }}
                    />
                </svg>
            ) : (
                <polygon
                    points="0,0 0,100 100,100 100,0 50,99"
                    style={{
                        fill: props.polygonColor,
                    }}
                />
            )}
        </svg>
    </div>
);
