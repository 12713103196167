import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import deepmerge from "deepmerge";

import { IStripePlan, IStripeCoupon } from "@fitplan/lib/types";

import "./subscribeButtons.scss";
import { AuthFormStrings } from "../strings/AuthStrings";
import SubscribeButtons from "./subscribeButtons";
import { IPromoData } from "pages/subscribe";

export interface Props {
    stripeProductId?: string;
    stripePlans?: IStripePlan[];
    title?: string;
    subtitle?: string;
    partner?: string;
    mobileImageSrc?: string;
    backgroundColor?: string;
    filter?: (plan: IStripePlan) => boolean;
    extraStrings?: { [name: string]: { [name: string]: string } };
    newCustomersOnly?: React.ReactNode;
    allowCoupon?: boolean;
    promoData?: IPromoData;
    couponCode?: string;
}

export const SubscribeSection: React.SFC<Props> = ({
    couponCode,
    mobileImageSrc,
    stripePlans,
    stripeProductId,
    filter,
    partner,
    backgroundColor,
    newCustomersOnly,
    title,
    subtitle,
    allowCoupon,
    promoData,
    extraStrings = {},
    children,
    ...props
}) => {
    const data = useStaticQuery(graphql`
        query SubscribeSection {
            subscribePage {
                title
                subtitle
            }
            homeSubscriptionBlock {
                primaryButton
                secondaryButton
            }
            startPage {
                success
                button
                exit
            }
        }
    `);
    return (
        <AuthFormStrings data={deepmerge(data, extraStrings)}>
            <SubscribeButtons
                stripeProductId={stripeProductId}
                stripePlans={stripePlans}
                couponCode={couponCode}
                promoData={promoData}
                backgroundColor={backgroundColor}
                filter={filter}
                partner={partner}
                mobileImageSrc={mobileImageSrc}
                newCustomersOnly={newCustomersOnly}
                allowCoupon={allowCoupon}
                title={title}
                subtitle={subtitle}
            >
                {children}
            </SubscribeButtons>
        </AuthFormStrings>
    );
};
