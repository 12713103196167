import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { localeTextGetter } from "../../utils/l10n";
import { stripeProductId } from "../../config";
import { IStripePlan, IStripeCoupon } from "@fitplan/lib/types";

import "./subscribeButtons.scss";
import { SubscribeSection } from "./SubscribeSection";
import { useState } from "react";
import useMount from "react-use/lib/useMount";
import { IPromoData } from "pages/subscribe";

export interface Props {
    title?: string;
    subtitle?: string;
    mobileImageSrc?: string;
    filter?: (plan: IStripePlan) => boolean;
    newCustomersOnly?: React.ReactNode;

    couponCode?: string;
    promoData?: IPromoData,
}

export const Subscribe: React.SFC<Props> = ({
    promoData,
    couponCode,
    mobileImageSrc,
    subtitle,
    title,
    filter,
    newCustomersOnly,
    children,
    ...props
}) => {
    const data = useStaticQuery(graphql`
        query Subscribe {
            allStripePlans(
                filter: { amount: { gt: 0 }, interval: { ne: "onetime" } }
            ) {
                edges {
                    node {
                        name: nickname
                        created
                        product
                        interval
                        interval_count
                        trial_period_days
                        usage_type
                        active
                        currency
                        amount
                        planId
                    }
                }
            }
        }
    `);
    const { allStripePlans } = React.useMemo(() => localeTextGetter(data), [
        data,
    ]);
    const planData = allStripePlans.edges.map((edge: any) => edge.node);

    return (
        <SubscribeSection
            stripeProductId={stripeProductId}
            stripePlans={planData}
            promoData={promoData}
            title={title}
            subtitle={subtitle}
            mobileImageSrc={mobileImageSrc}
            couponCode={couponCode}
            filter={filter || selectPlans}
            newCustomersOnly={newCustomersOnly}
        >{children}</SubscribeSection>
    );
};

const interval = (plan: IStripePlan & { intervalCount?: number }) => plan.interval_count || plan.intervalCount;
const selectPlans = (plan: IStripePlan) => {
    return (plan.interval === "year" && interval(plan) === 1)
        || (plan.interval === "month" && interval(plan) === 1);
}
